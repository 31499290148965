/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  function goToTabFromURL() {
    const i = setInterval(() => {
      clearInterval(i);
      // activate the tab given as parameter in url
      const $tabsNav = $('.tabs-nav');
      const $tabsNavLis = $tabsNav.children('li');
      // eslint-disable-next-line no-restricted-globals
      if (location.hash) {
        const panelRef = window.location.hash;
        $tabsNavLis
          .siblings()
          .removeClass('active')
          .end()
          .find($(`a[href=${panelRef}]`))
          .parent()
          .addClass('active');
        $tabsNavLis
          .parent()
          .next()
          .children('.tab-content')
          .stop(true, true)
          .hide()
          .siblings(`div${panelRef}`)
          .fadeIn();
      }
    }, 300);
  }

  const Sage = {
    // All pages
    common: {
      init() {
        // JavaScript to be fired on all pages
      },
      finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init() {
        // JavaScript to be fired on the home page
      },
      finalize() {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    expertise: {
      init() {
        // JavaScript to be fired on the services page
        goToTabFromURL();
      },
    },
    clients: {
      init() {
        // JavaScript to be fired on the clients page
        goToTabFromURL();
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  const UTIL = {
    fire(func, funcname, args) {
      let fire;
      const namespace = Sage;
      // eslint-disable-next-line no-param-reassign
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, '_').split(/\s+/),
        (_i, classnm) => {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        }
      );

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
